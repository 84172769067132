import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";

//stylesheets importing
import "./assets/styles/customUI.css";
import "./assets/styles/buttonStyles.css";
import "./assets/styles/inputStyle.css";
import "./assets/styles/layoutStyles.css";
import "./assets/styles/fontStyle.css";
//for including the tailwind.css
import "./assets/styles/tailwind.css";

import Header from "./Components/header";
import Footer from './Components/Footer';

//pages
import Home from "./Pages/Home";
import Login from "./Pages/Login";
import Signup from "./Pages/Signup";
import ProductDetails from "./Pages/ProductDetails";
import FogotPassword from "./Pages/FogotPassword";
import ResetPassword from "./Pages/ResetPassword";

function App() {
    return (
        <BrowserRouter>
            <Header />
            <Routes>
                <Route path="/" Component={Home} />
                <Route path="/login" Component={Login} />
                <Route path="/signup" Component={Signup} />
                <Route path="/forgotpassword" Component={FogotPassword} />
                <Route path="/resetpassword/:token" Component={ResetPassword} />
                <Route path="/product/:id" Component={ProductDetails} />
            </Routes>
            <Footer />
        </BrowserRouter>
    );
}

export default App;
