import React from "react";
import Slider from "react-slick";
import {
    Grid
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ProductCarousel = ({ datas, Component,defaultslides }) => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        autoplaySpeed: 300,
        arrows: true,
        slidesToShow: defaultslides,
        slidesToScroll: defaultslides,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
        nextArrow: <ArrowForwardIosIcon />,
        prevArrow: <ArrowBackIosIcon />,
    };

    return (
        <Slider className="slider" {...settings}>
            {datas?.map((data) => (
                <Grid sx={{ width: "100%" }} key={data._id} item>
                    <Component data={data}/>
                </Grid>
            ))}
        </Slider>
    );
};

export default ProductCarousel;
