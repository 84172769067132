import React from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import MyCarousel from "../Components/Carousel";
import Collage from "../Components/Collage";
import HighlightProducts from "../Components/HighlightProducts";
import BrandsCarousel from "../Components/BrandsCarousel";

const theme = createTheme(); // Creating a default theme

export default function Home() {
    return (
        <>
            <ThemeProvider theme={theme}>
                <MyCarousel />
                <Collage />
                <BrandsCarousel />
                <HighlightProducts />
            </ThemeProvider>
        </>
    );
}
