import axios from "axios";
const token = localStorage.getItem("token")
    ? localStorage.getItem("token")
    : "";
const instance = axios.create({
    baseURL: "https://onlineshop-backend.bluestream.io/api/v1",
    headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
    },
});



export default instance;
