import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Client from "../../utils/axiosInstance.util";

const initialState = {
    user: {},
    isLoggedIn: false,
    loading: false,
    error: "",
    success: false,
    forgotSuccess: false,
};

export const userLogin = createAsyncThunk(
    "user/login",
    async (payload, { rejectWithValue }) => {
        try {
            const { data } = await Client.post("/user/login", payload);
            console.log("response from login", data);
            localStorage.setItem("token", data.data.accessToken);
            return data.data;
        } catch (error) {
            console.error("error on login", error);
            rejectWithValue("Invalid credentials. Please try again!");
        }
    }
);

export const forgotPassword = createAsyncThunk(
    "user/forgotpassword",
    async (payload, { rejectWithValue }) => {
        try {
            const { data } = await Client.post(
                "/user/forgot-password",
                payload
            );
            console.log("response from forgot", data);
            return data.data;
        } catch (error) {
            console.error("error on forgot", error);
            rejectWithValue("Sorry we couldn't process the request. Please try again!");
        }
    }
);

export const resetPassword = createAsyncThunk(
    "user/resetpassword",
    async ({ token, password }, { rejectWithValue }) => {
        try {
            const { data } = await Client.post(
                "/user/reset-password",
                { newPassword: password },
                {
                    headers: {
                        authorization: `Bearer ${token}`,
                    },
                }
            );
            console.log("response from forgot", data);
            return data.data;
        } catch (error) {
            console.error("error on reset", error);
            rejectWithValue("Sorry unable to change the password!");
        }
    }
);

export const userSignup = createAsyncThunk(
    "user/signup",
    async (payload, { rejectWithValue }) => {
        try {
            const { data } = await Client.post("/user/register", payload);
            console.log("response from signup", data);
            localStorage.setItem("token", data.data.accessToken);
            return data.data;
        } catch (error) {
            console.error("error on signup", error);
            rejectWithValue("Sorry can't create account. Please try again later!");
        }
    }
);

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        resetSuccess: (state) => {
            state.success = false;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(userLogin.fulfilled, (state, action) => {
            state.isLoggedIn = true;
            state.user = action.payload;
            state.loading = false;
            state.success = true;
        });
        builder.addCase(userLogin.pending, (state, action) => {
            state.loading = true;
            state.isLoggedIn = false;
            state.user = {};
        });
        builder.addCase(userLogin.rejected, (state, action) => {
            state.isLoggedIn = false;
            state.loading = false;
            state.error = action.payload;
        });
        builder.addCase(userSignup.fulfilled, (state, action) => {
            state.isLoggedIn = true;
            state.user = action.payload;
            state.loading = false;
            state.success = true;
        });
        builder.addCase(userSignup.pending, (state, action) => {
            state.loading = true;
            state.isLoggedIn = false;
            state.user = {};
        });
        builder.addCase(userSignup.rejected, (state, action) => {
            state.isLoggedIn = false;
            state.loading = false;
            state.error = action.payload;
        });
        builder.addCase(forgotPassword.fulfilled, (state, action) => {
            state.forgotSuccess = true;
            state.loading = false;
        });
        builder.addCase(forgotPassword.pending, (state, action) => {
            state.loading = true;
            state.forgotSuccess = false;
        });
        builder.addCase(forgotPassword.rejected, (state, action) => {
            state.forgotSuccess = false;
            state.loading = false;
            state.error = action.payload;
        });
        builder.addCase(resetPassword.fulfilled, (state, action) => {
            state.success = true;
            state.loading = false;
        });
        builder.addCase(resetPassword.pending, (state, action) => {
            state.loading = true;
            state.success = false;
        });
        builder.addCase(resetPassword.rejected, (state, action) => {
            state.success = false;
            state.loading = false;
            state.error = action.payload;
        });
    },
});

// Action creators are generated for each case reducer function
export const { resetSuccess } = userSlice.actions


export default userSlice.reducer;
