import React from "react";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import { object, string } from "yup";
import { forgotPassword } from "../redux/reducers/user.reducer";
import Alert from "../Components/Alertmessage";

export default function FogotPassword() {
    const validationSchema = object({
        email: string().email("Invalid email address").required("Required"),
    });

    const dispatch = useDispatch();
    const { forgotSuccess, error } = useSelector((state) => state.userReducer);

    const handleSubmit = (values) => {
        // console.log("onsubmit values on login", values);
        dispatch(forgotPassword(values));
    };

    return (
        <div className="login">
            <div className="loginBox">
                <h3>Forgot Password</h3>
                {forgotSuccess && (
                    <Alert
                        msg="We have sent a mail to your mail Id Please validate your mail address."
                        severity="success"
                    />
                )}
                {error.length ? <Alert msg={error} severity="error" /> : ""}
                <Formik
                    initialValues={{ email: "" }}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting }) => {
                        handleSubmit(values);
                        setSubmitting(false);
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        /* and other goodies */
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <div className="mt-3">
                                <label htmlFor="email">Email</label>
                                <input
                                    type="email"
                                    name="email"
                                    className="loginInput"
                                    placeholder="please enter you email address"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.email}
                                />
                            </div>
                            <p className="text-rose-500 text-sm">
                                {errors.email && touched.email && errors.email}
                            </p>
                            <div className="flex flex-col">
                                <Button
                                    sx={{ mt: 3 }}
                                    variant="outlined"
                                    size="large"
                                    className="shopNowbtn"
                                    type="submit"
                                    disabled={isSubmitting}
                                >
                                    Submit
                                </Button>
                                <Link to="/login" className="link text-center">
                                    Cancel
                                </Link>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
        </div>
    );
}
