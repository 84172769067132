import React,{useEffect} from "react";
import { Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import { object, string } from "yup";

import Alert from "../Components/Alertmessage";
import { resetSuccess, userLogin } from "../redux/reducers/user.reducer";

export default function Login() {
    // const [showPassword, seShow] = useState(false);

    const validationSchema = object({
        email: string().email("Invalid email address").required("Required"),
        password: string()
            .required("No password provided")
            .min(8, "Password is too short - should be 8 characters minimum"),
    });

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { success, error } = useSelector((state) => state.userReducer);

    useEffect(() => {
        if (success) {
            dispatch(resetSuccess())
            navigate("/");
        }
    }, [success, navigate,dispatch]);

    const handleSubmit = (values) => {
        // console.log("onsubmit values on login", values);
        dispatch(userLogin(values));
    };

    return (
        <div className="login">
            <div className="loginBox">
                <h3>Sign In</h3>
                {error.length ? <Alert msg={error} severity="error" /> : ""}
                <Formik
                    initialValues={{ email: "", password: "" }}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting }) => {
                        handleSubmit(values);
                        setSubmitting(false);
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        /* and other goodies */
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <div className="mt-3">
                                <label htmlFor="email">Email</label>
                                <input
                                    type="email"
                                    name="email"
                                    className="loginInput"
                                    placeholder="please enter you email address"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.email}
                                />
                            </div>
                            <p className="text-rose-500 text-sm">
                                {errors.email && touched.email && errors.email}
                            </p>
                            <div className="mt-3">
                                <label htmlFor="email">Password</label>
                                <input
                                    type="password"
                                    name="password"
                                    className="loginInput"
                                    placeholder="please enter you Password"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.password}
                                />
                            </div>
                            <p className="text-rose-500 text-sm">
                                {errors.password &&
                                    touched.password &&
                                    errors.password}
                            </p>
                            <div className="flex flex-col">
                                <Link className="link" to="/forgotpassword">
                                    Forgot Password?
                                </Link>
                                <Button
                                    sx={{ mt: 3 }}
                                    variant="outlined"
                                    size="large"
                                    className="shopNowbtn"
                                    type="submit"
                                    disabled={isSubmitting}
                                >
                                    Login
                                </Button>
                                <Link to="/signup" className="link">
                                    Don't have an account?
                                </Link>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
        </div>
    );
}
