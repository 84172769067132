import React from "react";
import Rating from "@mui/material/Rating";

export default function RatingComponent({ ratingvalue, readonly, reviews }) {
    return (
        <div className="ratingdiv">
            <Rating
                name="half-rating-read"
                value={ratingvalue}
                precision={0.5}
                readOnly={readonly}
            />
            <p className="reviewno">({reviews} Reviews)</p>
        </div>
    );
}
