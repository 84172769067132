import React from "react";
import { useSelector } from "react-redux";
import ProductCarousel from "./CardCarousel";



export default function BrandsCarousel() {

    const { brands } = useSelector((state) => state.brandReducer);

    return (
        <div className="brandscarousel">
            <h3 className="subheading">Shop By Category</h3>
            {/* <p className="subdescription">The World's Premium Brands In One Destination.</p> */}
            <ProductCarousel
                datas={brands}
                Component={ImageComponent}
                defaultslides={3}
            />
        </div>
    );
}

function ImageComponent({ data }) {
    return <img className="brandImage" src={`http://onlineshop-backend.bluestream.io${data.logo}`} alt="loading..." />;
}
