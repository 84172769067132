import React from "react";
import { Grid } from "@mui/material";
import pot from "../assets/images/pot.jpg";
import clay from "../assets/images/clay.jpg";
import plant from "../assets/images/plant.jpg";
import seeds from "../assets/images/seeds.jpg";
function Collage() {
    return (
        <>
            <Grid container spacing={2} className="collageContainer">
                <Grid item lg={5} sm={4} xs={12}>
                    <div className="collageCard mx-1 mx-sm-0">
                        <img
                            src={pot}
                            alt="loading.."
                        />
                        <div className="collageCardcontent">
                            <a href="#">TRENDING NOW</a>
                            <h6>Ancient way of Pot Making</h6>
                            <a href="#">Pot Makers</a>
                        </div>
                    </div>
                </Grid>
                <Grid item lg={7} sm={8} xs={12}>
                    <div className="h-50 ms-1 mb-1">
                        <div className="collageCard">
                            <img
                                src={clay}
                                alt=""
                                srcset=""
                            />

                            <div className="collageCardcontent">
                                <a href="#">TRENDING NOW</a>
                                <h6>Clay and its Benefits</h6>
                                <a href="#">Wardrobe Discover Now</a>
                            </div>
                        </div>
                    </div>
                    <Grid
                        container
                        spacing={2}
                        className="h-50"
                        sx={{ width: "100%", marginLeft: "4px" }}
                    >
                        <Grid item xs={12} sm={6}>
                            <div className="collageCard">
                                <img
                                    src={plant}
                                    alt=""
                                    srcset=""
                                />

                                <div className="smallcardcontent">
                                    <a href="#">TRENDING NOW</a>
                                    <h6>Grow More Plants</h6>
                                    <a href="#">Wardrobe Discover Now</a>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <div className="collageCard">
                                <img
                                    src={seeds}
                                    alt=""
                                    srcset=""
                                />

                                <div className="smallcardcontent">
                                    <a href="#">TRENDING NOW</a>
                                    <h6>How to choose quality seeds</h6>
                                    <a href="#">Wardrobe Discover Now</a>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default Collage;
