import React, { useEffect } from "react";
import { Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { resetSuccess, userSignup } from "../redux/reducers/user.reducer";
import { Formik } from "formik";
import { object, string } from "yup";
import Alert from "../Components/Alertmessage";

export default function Signup() {
    //yup validation schema..
    const validationSchema = object({
        email: string()
            .required("Email is Required")
            .email("Invalid email address"),
        password: string()
            .required("No password provided")
            .min(8, "Password is too short - should be 8 characters minimum"),
        name: string().required("No username provided"),
        phoneno: string()
            .required("Phone is required!")
            .length(10, "Phone number should contain 10 numbers!"),
    });

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { success, error } = useSelector((state) => state.userReducer);

    useEffect(() => {
        if (success) {
            navigate("/");
            dispatch(resetSuccess())
        }
    }, [success, navigate,dispatch]);

    const handleSubmit = (values) => {
        dispatch(userSignup(values));
    };

    return (
        <div className="login">
            <div className="loginBox">
                <h3>Sign Up</h3>
                {error.length ? <Alert msg={error} severity="error" /> : ""}
                <Formik
                    initialValues={{
                        email: "",
                        name: "",
                        password: "",
                        phoneno: "",
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting }) => {
                        handleSubmit(values);
                        setSubmitting(false);
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        /* and other goodies */
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <div className="mt-3">
                                <label htmlFor="name">Username</label>
                                <input
                                    type="text"
                                    name="name"
                                    className="loginInput"
                                    placeholder="please enter your username"
                                    value={values.name}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                />
                            </div>
                            <p className="text-rose-500 text-sm">
                                {errors.name && touched.name && errors.name}
                            </p>
                            <div className="mt-3">
                                <label htmlFor="email">Email</label>
                                <input
                                    type="email"
                                    name="email"
                                    className="loginInput"
                                    placeholder="please enter you email address"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.email}
                                />
                            </div>
                            <p className="text-rose-500 text-sm">
                                {errors.email && touched.email && errors.email}
                            </p>
                            <div className="mt-3">
                                <label htmlFor="phoneno">Phone</label>
                                <input
                                    type="text"
                                    name="phoneno"
                                    className="loginInput"
                                    placeholder="please enter your Phone number"
                                    value={values.phoneno}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                />
                            </div>
                            <p className="text-rose-500 text-sm">
                                {errors.phoneno &&
                                    touched.phoneno &&
                                    errors.phoneno}
                            </p>
                            <div className="mt-3">
                                <label htmlFor="password">Password</label>
                                <input
                                    type="password"
                                    name="password"
                                    className="loginInput"
                                    placeholder="please enter you Password"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.password}
                                />
                            </div>
                            <p className="text-rose-500 text-sm">
                                {errors.password &&
                                    touched.password &&
                                    errors.password}
                            </p>
                            <div className="flex flex-col">
                                <Link className="link" to="/login">
                                    Already have an account?
                                </Link>
                                <Button
                                    sx={{ mt: 3 }}
                                    variant="outlined"
                                    size="large"
                                    className="shopNowbtn"
                                    onClick={handleSubmit}
                                >
                                    Signup
                                </Button>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
        </div>
    );
}
