import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Client from "../../utils/axiosInstance.util";

const initialState = {
    offers: [],
    loading: false,
    error: "",
};

export const getOffers = createAsyncThunk(
    "offers/get",
    async (_, { rejectWithValue }) => {
        try {
            const { data } = await Client.get("/offer/all-offers");
            console.log("data from offers", data);
            return data.data.data;
        } catch (error) {
            console.error("error on get all offers api", error);
            rejectWithValue(error.response.data);
        }
    }
);

const offerSlice = createSlice({
    name: "offers",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getOffers.fulfilled,(state,action) => {
            // console.log("fullfilled",action.payload);
            state.offers = action.payload;
            state.loading = false;
        })
        builder.addCase(getOffers.pending,(state,action) => {
            state.loading = true;
        })
        builder.addCase(getOffers.rejected,(state,action) => {
            state.loading = false;
            state.error = action.payload;
        })
    },
});

export default offerSlice.reducer;
