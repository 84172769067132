import React from "react";
import { Card, CardContent } from "@mui/material";
import Rating from "./Rating";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import PreviewIcon from "@mui/icons-material/Preview";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { useNavigate } from "react-router-dom";

export default function FeaturedCard({ data }) {

    const navigate = useNavigate();
    const handlePreview = (id) => {
        navigate(`/product/${id}`);
    }
    return (
        <Card className="featuredcard">
            <div
                className="cardmedia"
                style={{ backgroundImage: `url(http://onlineshop-backend.bluestream.io${data.image[0]})` }}
            >
                <button className="addtowishlistbtn">
                    <FavoriteBorderIcon />
                </button>
                <div className="cardmediafooter">
                    <button className="nostylebtn">
                        <AddShoppingCartIcon />
                    </button>
                    <button className="nostylebtn">|</button>
                    <button className="nostylebtn" onClick={() => handlePreview(data._id)}>
                        <PreviewIcon />
                    </button>
                </div>
            </div>
            <CardContent sx={{cursor: 'pointer'}} onClick={() => handlePreview(data._id)}>
                <div className="product-categories">
                    <p>{data.catagory}</p>
                </div>
                <h5 className="featuredProductname">{data.name}</h5>
                <div className="product-price">
                    <span className="new-price">{data.CurrentPrice}</span>
                    <span className="old-price">
                        <strike>{data.MRP}</strike>
                    </span>
                </div>
                <Rating ratingvalue={3} readonly={true} reviews={3} />
            </CardContent>
        </Card>
    );
}
