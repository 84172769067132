import React, { useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import { Badge, Popover } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import Search from "./Search";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getBrands } from "../redux/reducers/brand.reducer";
import brandLogo from "../assets/images/brandlogo.png";
import greenBasicLogo from "../assets/images/GreenBasicLogo.png"
const pages = ["Home", "Categories", "Branding"];

function ResponsiveAppBar() {
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const [anchorElBrand, setAnchorElBrand] = React.useState(null);

    const token = localStorage.getItem("token");
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { brands } = useSelector((state) => state.brandReducer);

    useEffect(() => {
        dispatch(getBrands());
    }, [dispatch]);

    useEffect(() => {
        console.log("brands on head", brands);
    }, [brands]);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        console.log("target", event.currentTarget);
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const logout = () => {
        localStorage.removeItem("token");
        window.location.reload();
    };

    //dropdown button
    const anchorRef = React.useRef(null);
    const brandRef = React.useRef(null);

    return (
        <AppBar position="static">
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    {/* <AdbIcon
                        sx={{ display: { xs: "none", md: "flex" }, mr: 1 }}
                    /> */}
                    {/* <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        href="#app-bar-with-responsive-menu"
                        sx={{
                            mr: 2,
                            display: { xs: "none", md: "flex" },
                            fontFamily: "monospace",
                            fontWeight: 700,
                            letterSpacing: ".3rem",
                            color: "inherit",
                            textDecoration: "none",
                        }}
                    >
                        LOGO
                    </Typography> */}
                    <img className= "logo-image" src={greenBasicLogo} alt="/"/>
                    {/* this is the part which shows on mobile devices */}
                    <Box
                        sx={{
                            flexGrow: 1,
                            display: { xs: "flex", md: "none" },
                        }}
                    >
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: "block", md: "none" },
                            }}
                        >
                            {pages.map((page) => (
                                <MenuItem
                                    key={page}
                                    onClick={handleCloseNavMenu}
                                >
                                    <Typography textAlign="center">
                                        {page}
                                    </Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                    <AdbIcon
                        sx={{ display: { xs: "flex", md: "none" }, mr: 1 }}
                    />
                    <Typography
                        variant="h5"
                        noWrap
                        component="a"
                        href="#app-bar-with-responsive-menu"
                        sx={{
                            mr: 2,
                            display: { xs: "flex", md: "none" },
                            flexGrow: 1,
                            fontFamily: "monospace",
                            fontWeight: 700,
                            letterSpacing: ".3rem",
                            color: "inherit",
                            textDecoration: "none",
                        }}
                    >
                        LOGO
                    </Typography>
                    {/* this is the part which shows on larger devices */}
                    <Box
                        sx={{
                            flexGrow: 1,
                            display: { xs: "none", md: "flex" },
                            justifyContent: "center",
                        }}
                    >
                        <Button
                            sx={{ my: 2, color: "white", display: "block" }}
                            onClick={() => navigate("/")}
                        >
                            Home
                        </Button>
                        {/* <Button
                            onClick={handleOpenUserMenu}
                            sx={{
                                my: 2,
                                color: "white",
                                display: "flex",
                                justifyContent: "around",
                            }}
                            ref={anchorRef}
                            onMouseEnter={(e) =>
                                setAnchorElUser(e.currentTarget)
                            }
                            onMouseLeave={(e) => setAnchorElUser(null)}
                            id="categorybtn"
                            className="noborderbackgroundbutton"
                        >
                            <span>Categories</span>
                            <KeyboardArrowDownIcon />
                        </Button> */}
                        <Popover
                            id="mouse-over-popover"
                            sx={{
                                pointerEvents: "none",
                                marginTop: "1.5rem",
                            }}
                            open={Boolean(anchorElUser)}
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                            }}
                            onClose={() => setAnchorElUser(null)}
                            disableRestoreFocus
                        >
                            <Typography>Menu Content</Typography>
                        </Popover>
                        <Button
                            sx={{ my: 2, color: "white", display: "block" }}
                        >
                            Products
                        </Button>
                        <Button
                            onClick={handleOpenUserMenu}
                            sx={{
                                my: 2,
                                color: "white",
                                display: "flex",
                                justifyContent: "around",
                            }}
                            ref={brandRef}
                            onMouseEnter={(e) =>
                                setAnchorElBrand(e.currentTarget)
                            }
                            onMouseLeave={(e) => setAnchorElBrand(null)}
                            id="categorybtn"
                            className="noborderbackgroundbutton"
                        >
                            <span>Categories</span>
                            <KeyboardArrowDownIcon />
                        </Button>
                        <Popover
                            id="mouse-over-popover"
                            sx={{
                                pointerEvents: "none",
                                marginTop: "1.5rem",
                            }}
                            open={Boolean(anchorElBrand)}
                            anchorEl={anchorElBrand}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                            }}
                            onClose={() => setAnchorElBrand(null)}
                            disableRestoreFocus
                        >
                            <div className="p-4">
                                {brands?.map((brand) => (
                                    <p key={brand._id}>{brand.name}</p>
                                ))}
                            </div>
                        </Popover>
                    </Box>
                    <Box
                        sx={{
                            flexGrow: 0,
                            display: "flex",
                            justifyContent: "space-around",
                            alignItems: "center",
                        }}
                    >
                        <Search />
                        <Badge
                            sx={{ marginLeft: "2rem", marginRight: "2rem" }}
                            badgeContent={4}
                            color="warning"
                        >
                            <FavoriteBorderIcon />
                        </Badge>
                        <Badge
                            sx={{ marginRight: "2rem" }}
                            badgeContent={4}
                            color="warning"
                        >
                            <ShoppingCartOutlinedIcon />
                        </Badge>
                        {!token ? (
                            <Link className="" to="/login">
                                login
                            </Link>
                        ) : (
                            <button className="nostylebtn" onClick={logout}>
                                logout
                            </button>
                        )}
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}
export default ResponsiveAppBar;
