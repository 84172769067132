import { configureStore } from '@reduxjs/toolkit'
import userReducer from './reducers/user.reducer';
import productReducer from './reducers/product.reducer';
import offerReducer from './reducers/offer.reducer';
import brandReducer from './reducers/brand.reducer';

export const store = configureStore({
  reducer: {
    userReducer,
    productReducer,
    offerReducer,
    brandReducer
  },
})