import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

import ProductCarousel from "./CardCarousel";
import FeaturedCard from "./FeaturedCard";
import { getFeaturedProducts } from "../redux/reducers/product.reducer";
import Loader from "./Loader";

// const products = [
//     {
//         id: 1,
//         name: "Product 1",
//         description: "Description of Product 1",
//         image: "https://images.pexels.com/photos/15494493/pexels-photo-15494493/free-photo-of-ornate-footbridge-above-carrer-del-bisbe-street-in-barcelona-spain.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
//     },
//     {
//         id: 2,
//         name: "Product 2",
//         description: "Description of Product 2",
//         image: "https://images.pexels.com/photos/20257840/pexels-photo-20257840/free-photo-of-venice.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
//     },
//     {
//         id: 3,
//         name: "Product 3",
//         description: "Description of Product 3",
//         image: "https://images.pexels.com/photos/18667107/pexels-photo-18667107/free-photo-of-oculus-new-york.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
//     },
//     {
//         id: 4,
//         name: "Product 4",
//         description: "Description of Product 3",
//         image: "https://images.pexels.com/photos/18667107/pexels-photo-18667107/free-photo-of-oculus-new-york.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
//     },
//     {
//         id: 5,
//         name: "Product 5",
//         description: "Description of Product 1",
//         image: "https://images.pexels.com/photos/15494493/pexels-photo-15494493/free-photo-of-ornate-footbridge-above-carrer-del-bisbe-street-in-barcelona-spain.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
//     },
//     {
//         id: 6,
//         name: "Product 6",
//         description: "Description of Product 2",
//         image: "https://images.pexels.com/photos/20257840/pexels-photo-20257840/free-photo-of-venice.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
//     },
//     {
//         id: 7,
//         name: "Product 7",
//         description: "Description of Product 3",
//         image: "https://images.pexels.com/photos/18667107/pexels-photo-18667107/free-photo-of-oculus-new-york.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
//     },
//     {
//         id: 8,
//         name: "Product 8",
//         description: "Description of Product 3",
//         image: "https://images.pexels.com/photos/18667107/pexels-photo-18667107/free-photo-of-oculus-new-york.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
//     },
//     // Add more products as needed
// ];

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
            style={{
                padding: "0 5rem",
            }}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const featuretype = {
    0: "featured",
    1: "discounted",
    2: "all-products",
};

export default function HighlightProducts() {
    const [value, setValue] = useState(0);
    const dispatch = useDispatch();
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const { products, loading } = useSelector((state) => state.productReducer);

    //useEffect for finding the featured products according to the tab active
    useEffect(() => {
        dispatch(getFeaturedProducts(featuretype[value]));
    }, [dispatch, value]);

    return (
        <Box sx={{ width: "100%" }}>
            <Box>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    centered
                >
                    <Tab label="Featured" {...a11yProps(0)} />
                    <Tab label="Discounted" {...a11yProps(1)} />
                    <Tab label="All" {...a11yProps(2)} />
                </Tabs>
            </Box>
            {loading ? (
                <Loader />
            ) : (
                <>
                    <CustomTabPanel value={value} index={0}>
                        <ProductCarousel
                            datas={products}
                            Component={FeaturedCard}
                            defaultslides={4}
                        />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                        <ProductCarousel
                            datas={products}
                            Component={FeaturedCard}
                            defaultslides={4}
                        />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={2}>
                        <ProductCarousel
                            datas={products}
                            Component={FeaturedCard}
                            defaultslides={4}
                        />
                    </CustomTabPanel>
                </>
            )}
        </Box>
    );
}
