import React, { useEffect } from "react";
import Carousel from "react-material-ui-carousel";
import { Button, Paper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useDispatch, useSelector } from "react-redux";
import { getOffers } from "../redux/reducers/offer.reducer";

const useStyles = makeStyles((theme) => ({
    image: {
        height: "70vh",
        width: "100%",
        backgroundSize: "cover",
        backgroundPosition: "center",
    },
    content: {
        position: "absolute",
        top: "40%",
        left: "10%",
        color: "white",
        zIndex: 100,
    },
    imageContainer: {
        position: "relative",
        height: "100%",
        width: "100%",
    },
    overlay: {
        position: "absolute",
        top: 0,
        left: 0,
        height: "100%",
        width: "100%",
        background:
            "linear-gradient(to bottom, rgba(0,0,0,0.5) 0%,rgba(0,0,0,0) 100%)",
    },
}));

const MyCarousel = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const { offers } = useSelector((state) => state.offerReducer); //getting offers from reducer

    useEffect(() => {
        dispatch(getOffers());
    }, [dispatch]);

    return (
        <Carousel
            // className="customiseCarousel"
            navButtonsAlwaysVisible
            NextIcon={<ArrowForwardIosIcon />}
            PrevIcon={<ArrowBackIosNewIcon />}
            indicatorContainerProps={{
                style: {
                    left: "-37%",
                    top: "59%",
                    position: "absolute",
                    zIndex: 10,
                },
            }}
        >
            {offers?.map((offer) => (
                <div className={classes.imageContainer} key={offer._id}>
                    <div className={classes.overlay}></div>
                    <Paper
                        elevation={0}
                        className={classes.image}
                        style={{ backgroundImage: `url(http://onlineshop-backend.bluestream.io${offer.image})` }}
                    >
                        <div className={classes.content}>
                            {/* <Typography variant="h5">
                                {offer.offerName}
                            </Typography>
                            <Typography variant="body1">
                                {offer.Description}
                            </Typography> */}
                            {/* <Button
                                sx={{ mt: 3 }}
                                variant="outlined"
                                size="large"
                                className="shopNowbtn"
                            >
                                Shop Now
                            </Button> */}
                        </div>
                    </Paper>
                </div>
            ))}
        </Carousel>
    );
};

export default MyCarousel;
