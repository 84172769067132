import React, { useRef } from "react";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";

export default function Search() {
    const ref = useRef(null);
    const inputRef = useRef(null);
    const closeBtnRef = useRef(null);

    const handleOpen = () => {
        inputRef.current.classList.add("makeinputvisible");
        closeBtnRef.current.classList.add("closesearchbtnactive");
        ref.current.classList.add('searchbtninactive');
    };

    const handleClose = () => {
        inputRef.current.classList.remove("makeinputvisible");
        closeBtnRef.current.classList.remove("closesearchbtnactive");
        ref.current.classList.remove('searchbtninactive');
    };

    return (
        <>
            <div style={{position: 'relative'}}>
                <input
                    type="text"
                    className="searchInput"
                    placeholder="Search for Product.."
                    ref={inputRef}
                />
                <button
                    className="nostylebtn closesearchbtn"
                    style={{ color: "black" }}
                    onClick={handleClose}
                    ref={closeBtnRef}
                >
                    <CloseIcon />
                </button>
            </div>
            <button className="nostylebtn searchIconbtn" ref={ref} onClick={handleOpen}>
                <SearchIcon />
            </button>
        </>
    );
}
