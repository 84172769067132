import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Client from "../../utils/axiosInstance.util";

const initialState = {
    brands: [],
    loading: false,
    error: "",
    success: false,
};

export const getBrands = createAsyncThunk(
    "brands/get",
    async (_, { rejectWithValue }) => {
        try {
            const { data } = await Client.get("/category/get-all");
            console.log("response from brands", data);
            return data.data.data;
        } catch (error) {
            console.error("error on login", error);
            rejectWithValue("Invalid credentials. Please try again!");
        }
    }
);

const brandSlice = createSlice({
    name: "brands",
    initialState,
    reducers: {
        resetSuccess: (state) => {
            state.success = false;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getBrands.fulfilled, (state, action) => {
            state.brands = action.payload;
            state.loading = false;
            state.error = "";
        });
        builder.addCase(getBrands.pending, (state, action) => {
            state.loading = true;
            state.error = "";
        });
        builder.addCase(getBrands.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
    },
});

export const { resetSuccess } = brandSlice.actions;

export default brandSlice.reducer;
