import React from "react";
import AdbIcon from "@mui/icons-material/Adb";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { Typography } from "@mui/material";
import brandLogo from "../assets/images/brandlogo.png"
import greenBasicLogo from "../assets/images/GreenBasicLogo.png"
export default function Footer() {
    return (
        <>
            <footer className="pt-4 flex justify-center">
                <div className="container">
                    <div className="grid grid-cols-5 gap-4 my-1">
                        <div className="p-4 col-span-2">
                            <div className="flex">
                                {/* <AdbIcon
                                    sx={{
                                        display: { xs: "none", md: "flex" },
                                        mr: 1,
                                    }}
                                /> */}
                                {/* <Typography
                                    variant="h6"
                                    noWrap
                                    component="a"
                                    href="#app-bar-with-responsive-menu"
                                    sx={{
                                        mr: 2,
                                        display: { xs: "none", md: "flex" },
                                        fontFamily: "monospace",
                                        fontWeight: 700,
                                        letterSpacing: ".3rem",
                                        color: "inherit",
                                        textDecoration: "none",
                                    }}
                                >
                                    LOGO
                                </Typography> */}
                                <img className= "footer-logo-image" src={greenBasicLogo} alt="/"/>
                            </div>
                            <p className="text-[14px] leading-7">
                            Elevate your living, office and outdoor 
                            space with a variety of  plants grown 
                            and treated with utmost care just for you!
                            </p>
                            <div className="grid grid-cols-4 gap-4 border-2 rounded-md p-3 items-center">
                                <div className="p-4">
                                    <LocalPhoneIcon />
                                </div>
                                <div className="col-span-3">
                                    <p className="text-[14px]">
                                        Got Question? call us 24/7
                                    </p>
                                    <h5 className="text-blue-600">
                                        +0123456789
                                    </h5>
                                </div>
                            </div>
                        </div>
                        <div className=" p-4">
                            <h6 className="font-semibold text-[20px]">
                                Useful Links
                            </h6>
                            <p className="text-[14px] mt-3">About</p>
                            <p className="text-[14px] mt-1">Our Services</p>
                            <p className="text-[14px] mt-1">How to shop?</p>
                            <p className="text-[14px] mt-1">FAQ</p>
                            <p className="text-[14px] mt-1">Contact Us</p>
                        </div>
                        <div className="p-4">
                            <h6 className="font-semibold text-[20px]">
                                Customer Service
                            </h6>
                            <p className="text-[14px] mt-3">Payment Methods</p>
                            <p className="text-[14px] mt-1">Money-back guarantee</p>
                            <p className="text-[14px] mt-1">Returns</p>
                            <p className="text-[14px] mt-1">Shipping</p>
                            <p className="text-[14px] mt-1">Terms and condition</p>
                        </div>
                        <div className="p-4">
                            <h6 className="font-semibold text-[20px]">
                                My Account
                            </h6>
                            <p className="text-[14px] mt-3">Sign in</p>
                            <p className="text-[14px] mt-1">View Cart</p>
                            <p className="text-[14px] mt-1">My Wishlist</p>
                            <p className="text-[14px] mt-1">Track My Order</p>
                            <p className="text-[14px] mt-1">Help</p>
                        </div>
                    </div>
                    <p className="text-[14px] text-center my-2 border-t-2 pt-2">Copyright © 2024 Designed and Developed by <a href="https://bluestream.io/"> Bluestream.io</a></p>
                </div>
            </footer>
        </>
    );
}
