import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Client from "../../utils/axiosInstance.util";

const initialState = {
    products: [],
    loading: false,
    error: "",
    product: {}
};

export const getFeaturedProducts = createAsyncThunk(
    "products/featured",
    async (featured, { rejectWithValue }) => {
        try {
            const { data } = await Client.get(`/product/${featured}`);
            console.log('response data from featured',data.data.data);
            return data.data.data;
        } catch (error) {
            console.error("error on login", error);
            rejectWithValue(error.response.data);
        }
    }
);

// export const searchProducts = createAsyncThunk('product/search',(payload,{rejectWithValue}) => {

// })

export const getProductdetails = createAsyncThunk('product/details',async (id,{rejectWithValue}) => {
    try {
        const {data} = await Client.get(`/product/one-product/${id}`);
        console.log("get signle product",data.data);
        return data.data;
    } catch (error) {
        console.error("error on product dtails api",error);
        rejectWithValue("Sorry unable to find the product details. Try again later!")
    }
})

export const productSlice = createSlice({
    name: "product",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getFeaturedProducts.fulfilled, (state, action) => {
            state.loading = false;
            state.products = action.payload;
        });
        builder.addCase(getFeaturedProducts.pending, (state, action) => {
            state.loading = true;
            state.products = [];
        });
        builder.addCase(getFeaturedProducts.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
        builder.addCase(getProductdetails.fulfilled, (state, action) => {
            state.loading = false;
            state.product = action.payload;
        });
        builder.addCase(getProductdetails.pending, (state, action) => {
            state.loading = true;
            state.product = {};
        });
        builder.addCase(getProductdetails.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
    },
});

// Action creators are generated for each case reducer function

export default productSlice.reducer;
